import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usemembersList() {

    const toast = useToast();
  const json_fields = {
    "First Name" : "firstname",
    "Last Name" : "lastname",
    "Email" : "email",
    "Phone" : "mobile",
    "Address" : "address",
    "City" : "city",
    "Pincode" : "pincode",
  };

    const pageLength = ref(10);

    const columns = [
       
        {
            label: "Name",
            field: "firstname",
        },
        {
            label: "Email",
            field: "email",
        },
        {
            label: "Mobile",
            field: "mobile",
        },
        {
            label: "Address",
            field: "address",
        },
        {
            label: "Status",
            field: "status",
        },
        {
            label: "Action",
            field: "action",
        },
    ];

    var searchTerm = ref('');


    const members = ref([]);

    const statusVariant = computed(() => {
        const statusColor = {
            1: "light-success",
            0: "light-danger",
        };
        return (status) => statusColor[status];
    })

    const fetchmembers = async () => {
        store
            .dispatch('master-members/fetchmembers', {})
            .then(response => {

                if (response.data.result == 1) {

                    members.value = response.data.data;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }

            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Fetching members',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


  
    const refetchmembers = () => {
        fetchmembers();
    }


    const addmembers = async (formData) => {
        return store
            .dispatch('master-members/addmembers', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding members',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }


    const updatemembers = async (formData) => {
        return store
            .dispatch('master-members/updatemembers', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding members',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const deletemembers = async (formData) => {
        return store
            .dispatch('master-members/deletemembers', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding members',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const addUser = async (formData) => {
        return store
            .dispatch('app-user/addUser', formData)
            .then(response => {
                console.log(response);
                if (response.data.success && response.data.success == true) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User added successfuly',
                            icon: 'CheckIcon',
                            variant: 'Success',
                        },
                    })
                    return true;
                } else {
                    return { error: response.data.status };
                }
            })
            .catch((error) => {
                console.log(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error while adding user',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
                return false;
            })
    }



    return {
        fetchmembers,
        addmembers,
        refetchmembers,
        updatemembers,
        deletemembers,
        columns,
        pageLength,
        searchTerm,
        members,
        statusVariant,
        json_fields

    }
}